var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "invoice-preview-wrapper"
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": _vm.$router.currentRoute.params.id === undefined
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v("Error fetching summary data")]), _c('div', {
    staticClass: "alert-body"
  }, [_c('b-link', {
    staticClass: "alert-link"
  }, [_vm._v(" Halaman ini tidak bisa di refresh atau dibuka melalui direct link, ")]), _vm._v(" halaman bersifat one time access atau hanya dapat di akses melalui button pada kolom "), _c('strong', [_vm._v("Details")]), _vm._v(" summary. ")], 1)]), _vm.yo_isLoading ? _c('div', {
    staticClass: "progress-wrapper"
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" Memuat data… " + _vm._s(_vm.yo_isLoadingValue + '%') + " ")]), _c('b-progress', {
    key: _vm.yo_isLoadingValue,
    class: 'progress-bar-info',
    attrs: {
      "animated": "",
      "value": _vm.yo_isLoadingValue,
      "variant": "info",
      "max": "100"
    }
  })], 1) : _vm._e(), _vm.invoiceData ? _c('b-row', {
    staticClass: "invoice-preview"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "xl": "9",
      "md": "8"
    }
  }, [_c('b-card', {
    staticClass: "invoice-preview-card",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('div', [_c('div', {
    staticClass: "logo-wrapper"
  }, [_c('h3', {
    staticClass: "text-primary invoice-logo"
  }, [_vm._v(" TBBM - Pelayanan Kapal ")])]), _c('p', {
    staticClass: "card-text mb-25"
  }, [_vm._v("PT. Sarana Abadi Lestari")]), _c('p', {
    staticClass: "card-text mb-25"
  }, [_vm._v("Kec. Palaran, Kota Samarinda")]), _c('p', {
    staticClass: "card-text mb-25"
  }, [_vm._v(" +1 (123) 456 7891, +44 (876) 543 2198 ")]), _c('p', {
    staticClass: "card-text mb-0"
  }, [_vm._v("Kalimantan Timur 75261")])]), _c('div', {
    staticClass: "mt-md-0 mt-2"
  }, [_c('h4', {
    staticClass: "invoice-title"
  }, [_vm._v(" Summary "), _c('span', {
    staticClass: "invoice-number"
  }, [_vm._v("#" + _vm._s(_vm.form.form_chain_tbbm_id))])]), _c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "CalendarIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Date of Application')) + " ")])], 1), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime(_vm.form.schedule.created_at)) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "AnchorIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Date of Departure')) + " ")])], 1), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime(_vm.form.schedule.sandar_end_time) || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "BookOpenIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Activity')) + " ")])], 1), _c('td', {
    staticClass: "pb-50 text-capitalize"
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.kegiatan) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "CpuIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Jenis Kapal')) + " ")])], 1), _c('td', {
    staticClass: "pb-50 text-capitalize"
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.jenis_kapal) + " ")])]), _c('tr', [_c('th', [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "FlagIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Form Status')) + " ")])], 1), _c('td', [_c('b-badge', {
    attrs: {
      "variant": _vm.formStatusVariant(_vm.form.schedule.form_status)
    }
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.form_status) + " ")])], 1)]), _c('tr', [_c('th', [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "UserIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Pemilik')) + " ")])], 1), _c('td', [_vm._v(" " + _vm._s(_vm.form.schedule.pemilik) + " ")])])])])])]), _vm.form.schedule.jenis_kapal === 'Barge' ? _c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('div', {
    staticClass: "col col-md-6 p-0"
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "AnchorIcon"
    }
  }), _vm._v(" Main Boat "), _c('feather-icon', {
    attrs: {
      "icon": "AnchorIcon"
    }
  })], 1)], 1)]), _c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nama Kapal')) + " ")])]), _c('td', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "pb-50",
    attrs: {
      "title": "click to copy"
    },
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.form.dm_river_boat.boat_name);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.form.dm_river_boat.boat_name) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor PKK')) + " ")])]), _c('td', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "pb-50 cursor-pointer",
    attrs: {
      "title": "click to copy"
    },
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.form.schedule.no_pkk);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_pkk) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('GT')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.dm_river_boat.gt) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('LOA')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(_vm._s(_vm.form.dm_river_boat.loa) + " M")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Captain')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.dm_river_boat.captain_name) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Captain Telp')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.dm_river_boat.captain_telp) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Shipping Agent')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.dm_shipping_agent.shipping_name) + " ")])])])]), _c('div', {
    staticClass: "col col-md-6 p-0"
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "AnchorIcon"
    }
  }), _vm._v(" Tug Boat "), _c('feather-icon', {
    attrs: {
      "icon": "AnchorIcon"
    }
  })], 1)], 1)]), _c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nama Kapal')) + " ")])]), _c('td', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "pb-50",
    attrs: {
      "title": "click to copy"
    },
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.form.tug_boat.name_tug_boat);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.form.tug_boat.name_tug_boat) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor PKK')) + " ")])]), _c('td', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "pb-50 cursor-pointer",
    attrs: {
      "title": "click to copy"
    },
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.form.tug_boat.no_pkk_tug_boat);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.form.tug_boat.no_pkk_tug_boat) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('GT')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.tug_boat.gt_tug_boat) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('LOA')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(_vm._s(_vm.form.tug_boat.loa_tug_boat) + " M")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Captain')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.tug_boat.captain_name_tug_boat) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Captain Telp')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.tug_boat.captain_telp_tug_boat) + " ")])])])])])]) : _c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "AnchorIcon"
    }
  }), _vm._v(" KAPAL "), _c('feather-icon', {
    attrs: {
      "icon": "AnchorIcon"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('div', {
    staticClass: "col col-md-6 p-0"
  }, [_c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nama Kapal')) + " ")])]), _c('td', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "pb-50",
    attrs: {
      "title": "click to copy"
    },
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.form.dm_river_boat.boat_name);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.form.dm_river_boat.boat_name) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor PKK')) + " ")])]), _c('td', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "pb-50",
    attrs: {
      "title": "click to copy"
    },
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.form.schedule.no_pkk);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_pkk) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('GT')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.dm_river_boat.gt) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('LOA')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(_vm._s(_vm.form.dm_river_boat.loa) + " M")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Captain')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.dm_river_boat.captain_name) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Captain Telp')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.dm_river_boat.captain_telp) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Shipping Agent')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.dm_shipping_agent.shipping_name) + " ")])])])])])]), _vm.form.schedule.jenis_kapal === 'Barge' ? _c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CalendarIcon"
    }
  }), _vm._v(" Schedule "), _c('feather-icon', {
    attrs: {
      "icon": "CalendarIcon"
    }
  })], 1)], 1)])]), _c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('div', {
    staticClass: "col col-md-8 p-0 mt-0"
  }, [_c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('b-badge', [_vm._v(" Pemilik (1) " + _vm._s(this.pemilik_1) + " ")]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Volume Muatan')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatNumber(_vm.form.schedule.volume_muatan) || '-') + " Liter ")])]), this.pemilik_2 ? _c('b-badge', [_vm._v(" Pemilik (2) " + _vm._s(this.pemilik_2) + " ")]) : _vm._e(), this.pemilik_2 ? _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Volume Muatan')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatNumber(_vm.form.schedule.volume_muatan_tb) || '-') + " Liter ")])]) : _vm._e(), _vm.form.schedule.total_bunker ? _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Total Bunker')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatNumber(_vm.form.schedule.total_bunker) || '-') + " Liter ")])]) : _vm._e(), _vm.form.schedule.total_bunker ? _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor Ijin Bunker')) + " ")])]), _c('td', {
    staticClass: "pb-50",
    attrs: {
      "title": "click to copy"
    },
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.form.schedule.no_ijin_bunker);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_ijin_bunker || '-') + " ")])]) : _vm._e(), _c('hr'), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Asal Kapal')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.dm_asal_kapal.asal) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Rencana Sandar')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime(_vm.form.schedule.rencana_sandar)) + " ")])])], 1)])]), _c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('div', {
    staticClass: "col col-md-6 p-0"
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "AnchorIcon"
    }
  }), _vm._v(" Main Boat "), _c('feather-icon', {
    attrs: {
      "icon": "AnchorIcon"
    }
  })], 1), _c('h5', [_vm._v(_vm._s(_vm.form.dm_river_boat.boat_name))])], 1)]), _c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor Pindah')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_pindah || 'opsional') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor RKBM')) + " ")])]), _c('td', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "pb-50",
    attrs: {
      "title": "click to copy"
    },
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.form.schedule.no_rkbm);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_rkbm || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor PBMBB')) + " ")])]), _c('td', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "pb-50",
    attrs: {
      "title": "click to copy"
    },
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.form.schedule.no_pbmbb);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_pbmbb || '-') + " ")])])])]), _c('div', {
    staticClass: "col col-md-6 p-0"
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "AnchorIcon"
    }
  }), _vm._v(" Tug Boat "), _c('feather-icon', {
    attrs: {
      "icon": "AnchorIcon"
    }
  })], 1), _c('h5', [_vm._v(_vm._s(_vm.form.tug_boat.name_tug_boat))])], 1)]), _c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor Pindah')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_pindah_tb || 'opsional') + " ")])])])])])], 1) : _c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CalendarIcon"
    }
  }), _vm._v(" Schedule "), _c('feather-icon', {
    attrs: {
      "icon": "CalendarIcon"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('div', {
    staticClass: "col col-md-10 p-0"
  }, [_c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor Pindah')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_pindah || 'opsional') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor RKBM')) + " ")])]), _c('td', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "pb-50",
    attrs: {
      "title": "click to copy"
    },
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.form.schedule.no_rkbm);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_rkbm || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor PBMBB')) + " ")])]), _c('td', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "pb-50",
    attrs: {
      "title": "click to copy"
    },
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.form.schedule.no_pbmbb);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_pbmbb || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Volume Muatan')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.volume_muatan || '-') + " Liter ")])]), _vm.form.schedule.total_bunker ? _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Total Bunker')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.total_bunker || '-') + " Liter ")])]) : _vm._e(), _vm.form.schedule.total_bunker ? _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor Ijin Bunker')) + " ")])]), _c('td', {
    staticClass: "pb-50",
    attrs: {
      "title": "click to copy"
    },
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.form.schedule.no_ijin_bunker);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_ijin_bunker || '-') + " ")])]) : _vm._e(), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Asal Kapal')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.dm_asal_kapal.asal) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Rencana Sandar')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime(_vm.form.schedule.rencana_sandar)) + " ")])])])])])]), _c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "HardDriveIcon"
    }
  }), _vm._v(" Document "), _c('feather-icon', {
    attrs: {
      "icon": "HardDriveIcon"
    }
  })], 1), _c('br'), _c('strong', [_vm._v("[ click name file, to preview.]")])], 1)]), _c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, _vm._l(_vm.isHaveFile, function (item, index) {
    return _c('app-timeline-item', {
      key: index
    }, [_c('div', {
      staticClass: "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
    }), _c('p', [_c('b-img', {
      staticClass: "mr-1",
      attrs: {
        "src": _vm.icons(item),
        "height": "auto",
        "width": "20"
      }
    }), _c('span', {
      staticClass: "align-bottom"
    }, [_c('a', {
      staticStyle: {
        "color": "grey"
      },
      attrs: {
        "href": item.download_link,
        "target": "_blank",
        "rel": "noopener noreferrer"
      }
    }, [_vm._v(_vm._s(item.original_name))])])], 1), _c('small', {
      staticClass: "text-muted"
    }, [_vm._v("Size " + _vm._s(item.size) + " ")])]);
  }), 1)]), _c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CheckSquareIcon"
    }
  }), _vm._v(" Approvement "), _c('feather-icon', {
    attrs: {
      "icon": "CheckSquareIcon"
    }
  })], 1)], 1)]), _c('br'), _c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('div', {
    staticClass: "col col-md-12 p-0 mt-0"
  }, [_c('div', {
    staticClass: "p-0"
  }, [_c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Approve BY')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.approve_by || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Approve AT')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime(_vm.form.schedule.ijin_lengkap_time)) + " ")])])])])])])]), _c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('br'), _c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MapIcon"
    }
  }), _vm._v(" Jetty Man "), _c('feather-icon', {
    attrs: {
      "icon": "MapIcon"
    }
  })], 1), _c('br'), _vm._v(" [ Tujuan Kapal : " + _vm._s(_vm.form.schedule.tujuan_kapal) + " ] ")], 1)]), _c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('div', {
    staticClass: "col col-md-4 p-0 mt-0"
  }, [_c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Posisi Jetty')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.position_jetty || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Posisi Baris Jetty')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.position_baris_jetty || '-') + " ")])])])]), _vm.form.schedule.jenis_kapal === 'Barge' ? _c('div', {
    staticClass: "col col-md-4 p-0 mt-0"
  }, [_c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Posisi Jetty')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.position_jetty_tb || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Posisi Baris Jetty')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.position_baris_jetty_tb || '-') + " ")])])])]) : _vm._e()]), _c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('div', {
    staticClass: "col col-md-6 p-0"
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('h5', [_vm._v(_vm._s(_vm.form.dm_river_boat.boat_name))]), _c('span', {
    staticStyle: {
      "@media (min-width": "390px) and (max-width: 768px) {",
      "word-wrap": "break-word",
      "width": "50px",
      "white-space": "normal"
    }
  }, [_vm._v(_vm._s(this.form_realisasi.rka1))]), _c('br'), _c('strong', [_c('span', [_vm._v("Sandar → Berangkat")])])])]), _c('table', {
    staticClass: "mt-2 mt-xl-0 w-100 p-0"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Waktu Sandar')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime(_vm.form.schedule.sandar_start_time) || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Waktu Berangkat')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime(_vm.form.schedule.sandar_end_time) || '-') + " ")])])])]), _vm.form.schedule.jenis_kapal === 'Barge' ? _c('div', {
    staticClass: "col col-md-6 p-0"
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('h5', [_vm._v(_vm._s(_vm.form.tug_boat.name_tug_boat))]), _c('span', {
    staticStyle: {
      "@media (min-width": "390px) and (max-width: 768px) {",
      "word-wrap": "break-word",
      "width": "50px",
      "white-space": "normal"
    }
  }, [_vm._v(_vm._s(this.form_realisasi.rka2))]), _c('br'), _c('strong', [_c('span', [_vm._v("Sandar → Berangkat")])])])]), _c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Waktu Sandar')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime(_vm.form.tug_boat.tug_sandar_start_time) || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Waktu Berangkat')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime(_vm.form.tug_boat.tug_sandar_end_time) || '-') + " ")])])])]) : _vm._e()])]), _c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UnlockIcon"
    }
  }), _vm._v(" Admin SAL "), _c('feather-icon', {
    attrs: {
      "icon": "UnlockIcon"
    }
  })], 1)], 1)]), _c('b-alert', {
    attrs: {
      "variant": "info",
      "show": _vm.isNoRpkro
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v("[TBBM] LCT / KM / MT / SPOB / Sea Truck")]), _c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" jika hanya berkegiatan "), _c('b-link', {
    staticClass: "alert-link"
  }, [_vm._v(" BUNKER ")]), _vm._v(" maka tidak diperlukan pengisian Nomor RPKRO & Nomor SPOG. ")], 1)]), _c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 p-0"
  }, [_c('div', {
    staticClass: "col col-md-6 p-0"
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('h5', [_vm._v(_vm._s(_vm.form.dm_river_boat.boat_name))]), _c('span', {
    staticStyle: {
      "@media (min-width": "390px) and (max-width: 768px) {",
      "word-wrap": "break-word",
      "width": "50px",
      "white-space": "normal"
    }
  }, [_vm._v(" -split-time- ")]), _c('br'), _c('strong', [_c('span', {
    staticStyle: {
      "@media (min-width": "390px) and (max-width: 768px) {",
      "word-wrap": "break-word",
      "width": "50px",
      "white-space": "normal"
    }
  }, [_vm._v("Mulai Berkegiatan → Selesai Berkegiatan")])])])])]), _vm.form.schedule.jenis_kapal === 'Barge' ? _c('div', {
    staticClass: "col col-md-6 p-0"
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('h5', [_vm._v(_vm._s(_vm.form.tug_boat.name_tug_boat))]), _c('span', {
    staticStyle: {
      "@media (min-width": "390px) and (max-width: 768px) {",
      "word-wrap": "break-word",
      "width": "50px",
      "white-space": "normal"
    }
  }, [_vm._v(" -split-time- ")]), _c('br'), _c('strong', [_c('span', {
    staticStyle: {
      "@media (min-width": "390px) and (max-width: 768px) {",
      "word-wrap": "break-word",
      "width": "50px",
      "white-space": "normal"
    }
  }, [_vm._v("Mulai Berkegiatan → Selesai Berkegiatan")])])])])]) : _vm._e()]), _c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 p-0"
  }, [_c('div', {
    staticClass: "col col-md-6 p-0"
  }, [_c('table', {
    staticClass: "mt-2 mt-xl-0 w-100 p-0"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor RPKRO')) + " ")])]), _c('td', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "pb-50",
    attrs: {
      "title": "click to copy"
    },
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.form.schedule.no_rpkro);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_rpkro || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" Nomor PPK ")])]), _c('td', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "pb-50",
    attrs: {
      "title": "click to copy"
    },
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.form.schedule.no_ppk);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_ppk || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Disetujui Sandar')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime(_vm.form.schedule.disetujui_time) || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor SPOG')) + " ")])]), _c('td', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "pb-50",
    attrs: {
      "title": "click to copy"
    },
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.form.schedule.no_spog);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_spog || '-') + " ")])]), _c('tr', [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "AnchorIcon"
    }
  }), _vm._v(" [1] Waktu Berkegiatan "), _c('feather-icon', {
    attrs: {
      "icon": "AnchorIcon"
    }
  })], 1)], 1)])]), _c('b-badge', [_vm._v(" Pemilik (1) " + _vm._s(this.pemilik_1) + " ")]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Mulai')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime(_vm.form.schedule.kegiatan_start_time_p1) || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Selesai')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime(_vm.form.schedule.kegiatan_end_time_p1) || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Total')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form_realisasi.keg_p1) + " ")])]), this.pemilik_2 ? _c('b-badge', [_vm._v(" Pemilik (2) " + _vm._s(this.pemilik_2) + " ")]) : _vm._e(), this.pemilik_2 ? _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Mulai')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime(_vm.form.schedule.kegiatan_start_time_p2) || '-') + " ")])]) : _vm._e(), this.pemilik_2 ? _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Selesai')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime(_vm.form.schedule.kegiatan_end_time_p2) || '-') + " ")])]) : _vm._e(), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Total')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form_realisasi.keg_p2) + " ")])])], 1)]), _vm.form.schedule.jenis_kapal === 'Barge' ? _c('div', {
    staticClass: "col col-md-6 p-0"
  }, [_c('table', {
    staticClass: "mt-2 mt-xl-0 w-100 p-0"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor RPKRO')) + " ")])]), _c('td', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "pb-50",
    attrs: {
      "title": "click to copy"
    },
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.form.schedule.no_rpkro_tb);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_rpkro_tb || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" Nomor PPK ")])]), _c('td', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "pb-50",
    attrs: {
      "title": "click to copy"
    },
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.form.schedule.no_ppk_tb);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_ppk_tb || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Disetujui Sandar')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime(_vm.form.schedule.disetujui_time_tb) || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor SPOG')) + " ")])]), _c('td', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "pb-50",
    attrs: {
      "title": "click to copy"
    },
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.form.schedule.no_spog_tb);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_spog_tb || '-') + " ")])]), _c('tr', [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "AnchorIcon"
    }
  }), _vm._v(" [2] Waktu Berkegiatan "), _c('feather-icon', {
    attrs: {
      "icon": "AnchorIcon"
    }
  })], 1)], 1)])]), _c('b-badge', [_vm._v(" Pemilik (1) " + _vm._s(this.pemilik_1) + " ")]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Mulai')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime(_vm.form.schedule.kegiatan_start_time_tb_p1) || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Selesai')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime(_vm.form.schedule.kegiatan_end_time_tb_p1) || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Total')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form_realisasi.keg_tb_p1) + " ")])]), this.pemilik_2 ? _c('b-badge', [_vm._v(" Pemilik (2) " + _vm._s(this.pemilik_2) + " ")]) : _vm._e(), this.pemilik_2 ? _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Mulai')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime(_vm.form.schedule.kegiatan_start_time_tb_p2) || '-') + " ")])]) : _vm._e(), this.pemilik_2 ? _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Selesai')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime(_vm.form.schedule.kegiatan_end_time_tb_p2) || '-') + " ")])]) : _vm._e(), this.pemilik_2 ? _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" Total ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form_realisasi.keg_tb_p2) + " ")])]) : _vm._e()], 1)]) : _vm._e()])], 1), _c('b-card-body', [_c('strong', {
    staticClass: "mr-2 mb-2 text-center"
  }, [_c('span', {
    domProps: {
      "textContent": _vm._s("Form Perencanaan Created BY ".concat(_vm.form.created_by))
    }
  })])]), _c('br'), _c('hr', {
    staticClass: "invoice-spacing"
  }), _c('b-card-body', {
    staticClass: "invoice-padding pt-0"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Note: ")]), _c('span', [_vm._v(" It was a pleasure working with you and your team. We hope you will keep us in mind for future shipping. Thank You! ")])])], 1)], 1), _c('b-col', {
    staticClass: "invoice-actions",
    staticStyle: {
      "position": "fixed",
      "right": "0"
    },
    attrs: {
      "cols": "12",
      "md": "4",
      "xl": "3"
    }
  }, [_c('b-card', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mb-75",
    attrs: {
      "variant": "outline-secondary",
      "block": "",
      "to": _vm.$router.currentRoute.params.previous_link
    }
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "HomeIcon"
    }
  }), _vm._v(" Kembali ")], 1), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mb-75",
    attrs: {
      "variant": "success",
      "block": "",
      "to": {
        name: 'dashboard-monitoring-rrv-tbbm-view',
        params: {
          id: _vm.form.form_chain_tbbm_id,
          api: 'form_chain_tbbm',
          previous_link: _vm.$router.currentRoute
        }
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "PenToolIcon"
    }
  }), _vm._v(" Realisasikan ")], 1)], 1), _vm.showVerification() ? _c('b-card', [_c('h3', [_vm._v("Verification")]), _c('ol', [_vm.$can('TBBM', '') ? _c('span', [!_vm.form.schedule.no_spog ? _c('b-badge', {
    staticClass: "d-block",
    attrs: {
      "variant": 'light-warning'
    }
  }, [_vm._v(" " + _vm._s(_vm.form.dm_river_boat.boat_name) + " ")]) : _vm._e(), !_vm.form.schedule.no_spog ? _c('li', [_c('span', {
    domProps: {
      "innerHTML": _vm._s('Belum mengisi Nomor SPOG')
    }
  }), _c('feather-icon', {
    staticClass: "ml-25",
    attrs: {
      "icon": 'XCircleIcon'
    }
  })], 1) : _vm._e(), _vm.form.schedule.jenis_kapal === 'Barge' ? _c('span', [!_vm.form.schedule.no_spog_tb ? _c('b-badge', {
    staticClass: "d-block",
    attrs: {
      "variant": 'light-warning'
    }
  }, [_vm._v(" " + _vm._s(_vm.form.tug_boat.name_tug_boat) + " ")]) : _vm._e(), !_vm.form.schedule.no_spog_tb ? _c('li', [_c('span', {
    domProps: {
      "innerHTML": _vm._s('Belum mengisi Nomor SPOG')
    }
  }), _c('feather-icon', {
    staticClass: "ml-25",
    attrs: {
      "icon": 'XCircleIcon'
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1) : _vm._e(), _vm.$can('manage', 'special') ? _c('span', [!_vm.form.schedule.no_do_bl ? _c('li', [_c('span', {
    domProps: {
      "innerHTML": _vm._s('Belum melakukan approvement')
    }
  })]) : _vm._e()]) : _vm._e(), _vm.$can('SAL', '') ? _c('span', [!_vm.form.schedule.no_rpkro || !_vm.form.schedule.no_spog || !_vm.form.schedule.kegiatan_start_time_p1 || !_vm.form.schedule.kegiatan_start_time_p2 ? _c('b-badge', {
    staticClass: "d-block",
    attrs: {
      "variant": 'light-warning'
    }
  }, [_vm._v(" " + _vm._s(_vm.form.dm_river_boat.boat_name) + " ")]) : _vm._e(), !_vm.form.schedule.no_rpkro ? _c('li', [_c('span', {
    domProps: {
      "innerHTML": _vm._s('Belum mengisi Nomor RPKRO (Menyetujui boleh mengisi SPOG)')
    }
  }), _c('feather-icon', {
    staticClass: "ml-25",
    attrs: {
      "icon": 'XCircleIcon'
    }
  })], 1) : _vm._e(), !_vm.form.schedule.no_spog ? _c('li', [_c('span', {
    domProps: {
      "innerHTML": _vm._s('Belum mengisi Nomor SPOG')
    }
  }), _c('feather-icon', {
    staticClass: "ml-25",
    attrs: {
      "icon": 'XCircleIcon'
    }
  })], 1) : _vm._e(), !_vm.form.schedule.kegiatan_start_time_p1 || !_vm.form.schedule.kegiatan_start_time_p2 ? _c('li', [_c('span', {
    domProps: {
      "innerHTML": _vm._s('Belum mengisi waktu kegiatan')
    }
  }), _c('feather-icon', {
    staticClass: "ml-25",
    attrs: {
      "icon": 'XCircleIcon'
    }
  })], 1) : _vm._e(), _vm.form.schedule.jenis_kapal === 'Barge' ? _c('span', [!_vm.form.schedule.no_rpkro_tb || !_vm.form.schedule.no_spog_tb || !_vm.form.schedule.kegiatan_start_time_tb_p1 || !_vm.form.schedule.kegiatan_start_time_tb_p2 ? _c('b-badge', {
    staticClass: "d-block",
    attrs: {
      "variant": 'light-warning'
    }
  }, [_vm._v(" " + _vm._s(_vm.form.tug_boat.name_tug_boat) + " ")]) : _vm._e(), !_vm.form.schedule.no_rpkro_tb ? _c('li', [_c('span', {
    domProps: {
      "innerHTML": _vm._s('Belum mengisi Nomor RPKRO (Menyetujui boleh mengisi SPOG)')
    }
  }), _c('feather-icon', {
    staticClass: "ml-25",
    attrs: {
      "icon": 'XCircleIcon'
    }
  })], 1) : _vm._e(), !_vm.form.schedule.no_spog_tb ? _c('li', [_c('span', {
    domProps: {
      "innerHTML": _vm._s('Belum mengisi Nomor SPOG')
    }
  }), _c('feather-icon', {
    staticClass: "ml-25",
    attrs: {
      "icon": 'XCircleIcon'
    }
  })], 1) : _vm._e(), !_vm.form.schedule.kegiatan_start_time_tb_p1 || !_vm.form.schedule.kegiatan_start_time_tb_p2 ? _c('li', [_c('span', {
    domProps: {
      "innerHTML": _vm._s('Belum mengisi waktu kegiatan')
    }
  }), _c('feather-icon', {
    staticClass: "ml-25",
    attrs: {
      "icon": 'XCircleIcon'
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1) : _vm._e()])]) : _vm._e()], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }